import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { tokenNotExpired } from "angular2-jwt";
import { PublicModule } from "../public.module";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authToken: any;
  public user: any;
  public headers: HttpHeaders;
  public baseUrl: string = "";

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers.append("Content-Type", "application/json");
  }

  public setConfig() {
    this.baseUrl = environment.baseUrl;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set("Content-Type", "application/json");
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }

  public authenticateUser(user) {
    return this.http.post(
      this.baseUrl + "users/authenticate",
      JSON.stringify(user),
      {
        headers: this.headers,
      }
    );
  }

  public storeUserData(token, user) {
    localStorage.setItem("id_token", token);
    localStorage.setItem("usuario", JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  public forgot(email) {
    const obj = { email };
    return this.http.post(this.baseUrl + "users/forgot", JSON.stringify(obj), {
      headers: this.headers,
    });
  }

  public change(email, password, passwordNew) {
    const obj = { email, password, passwordNew };
    return this.http.post(this.baseUrl + "users/reset", JSON.stringify(obj), {
      headers: this.headers,
    });
  }

  public loadToken() {
    const token = localStorage.getItem("id_token");
    this.authToken = token;
  }
  public logout() {
    this.authToken = null;
    this.user = {};
    localStorage.clear();
  }
  public getToken() {
    this.loadToken();
    return this.authToken;
  }
  public loggedIn() {
    return tokenNotExpired("id_token");
  }
  public getUser() {
    return this.user;
  }
}
